import React, {useEffect, useState} from 'react';
import Container from '../Container';
import FlexBox from '../FlexBox';
import Icon from '../icon/Icon';
import Image from '../Image';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import NavLink from '../nav-link/NavLink';
import Typography, {Small} from '../Typography';
import StyledTopbar from './Topbar.style';
import {useSelector} from "react-redux";

const Topbar: React.FC = () => {
  // @ts-ignore
  const settings = useSelector(state => state.settings.data)
  const [currency, setCurrency] = useState(currencyList[0]);
  const [language, setLanguage] = useState(languageList[0]);

  const handleCurrencyClick = (curr) => () => {
    setCurrency(curr);
  };

  const handleLanguageClick = (lang) => () => {
    setLanguage(lang);
  };

  useEffect(() => {
    // get language from browser
    // console.log(navigator.language);
  }, []);

  return (
    <StyledTopbar>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <FlexBox className="topbar-left">
          <div className="logo">
            <img src="/assets/images/logo.png" alt="logo" />
          </div>
          <Typography as="a" color="white" href={"phone:+971" + settings.primary_phone}>
            <FlexBox alignItems="center">
              <Icon size="14px">icons/phone-call</Icon>
              <span> +971 {settings.primary_phone}</span>
            </FlexBox>
          </Typography>
          <Typography as="a" color="white" href={"mailto:+971" + settings.primary_email}>
            <FlexBox alignItems="center" ml="20px">
              <Icon size="14px">icons/mail</Icon>
              <span> {settings.primary_email}</span>
            </FlexBox>
          </Typography>


        </FlexBox>
        <FlexBox className="topbar-right" alignItems="center">
          <NavLink className="link" href="/contact">
            Contact Us ?
          </NavLink>
          {/*<NavLink className="link" href="#!">*/}
          {/*  Need Help?*/}
          {/*</NavLink>*/}
          <Menu
            direction="right"
            handler={
              <FlexBox
                className="dropdown-handler"
                alignItems="center"
                height="40px"
                mr="1.25rem"
              >
                <Image src={language.imgUrl} alt={language.title} />
                <Small fontWeight="600">{language.title}</Small>
                <Icon size="1rem">icons/chevron-down</Icon>
              </FlexBox>
            }
          >
            {languageList.map((item) => (
              <MenuItem key={item.title} onClick={handleLanguageClick(item)}>
                <Image
                  src={item.imgUrl}
                  borderRadius="2px"
                  mr="0.5rem"
                  alt={item.title}
                />
                <Small fontWeight="600">{item.title}</Small>
              </MenuItem>
            ))}
          </Menu>
          <Menu
            direction="right"
            handler={
              <FlexBox
                className="dropdown-handler"
                alignItems="center"
                height="40px"
              >
                <Image src={currency.imgUrl} alt={currency.title} />
                <Small fontWeight="600">{currency.title}</Small>
                <Icon size="1rem">icons/chevron-down</Icon>
              </FlexBox>
            }
          >
            {currencyList.map((item) => (
              <MenuItem key={item.title} onClick={handleCurrencyClick(item)}>
                <Image
                  src={item.imgUrl}
                  borderRadius="2px"
                  style={{maxHeight: 20}}
                  mr="0.5rem"
                  alt={item.title}
                />
                <Small fontWeight="600">{item.title}</Small>
              </MenuItem>
            ))}
          </Menu>
        </FlexBox>
      </Container>
    </StyledTopbar>
  );
};

const languageList = [
  {
    title: 'EN',
    imgUrl: '/assets/images/flags/usa.png',
  },
];

const currencyList = [
  {
    title: 'AED',
    imgUrl: 'https://cdn-icons-png.flaticon.com/512/555/555611.png',
  },
];

export default Topbar;
