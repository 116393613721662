import Footer from "@component/footer/Footer";
import Header from "@component/header/Header";
import MobileNavigationBar from "@component/mobile-navigation/MobileNavigationBar";
import Sticky from "@component/sticky/Sticky";
import Topbar from "@component/topbar/Topbar";
import Head from "next/head";
import React, {useEffect} from "react";
import StyledAppLayout from "./AppLayoutStyle";
import {useDispatch, useSelector} from "react-redux";
import {getCategories} from "@store/categoryReducer";
import {getSettings} from "@store/settingsReducer";
import dynamic from "next/dynamic";
const ReactWhatsapp =  dynamic(() => import('react-whatsapp'), {ssr: false})

type Props = {
  title?: string;
  navbar?: React.ReactChild;
};

const AppLayout: React.FC<Props> = ({
  children,
  navbar,
  title = "GenX | Online Shopping",
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSettings())
        dispatch(getCategories())
    }, [])

    // @ts-ignore
    const settings = useSelector(state => state.settings.data)

    return (
        <StyledAppLayout>
            <Head>
                <title>{settings?.app_title || title}</title>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
            </Head>

            <Topbar/>

            <div className="block fixed z-50 bottom-24 left-4 md:bottom-12 md:left-12">
                <ReactWhatsapp number={settings?.whatsup_number} message="Hi, GEN-X" element="a">
                    <img className="w-12 md:w-14" role="button" src="/assets/images/icons/whatsapp.png" alt=""/>
                </ReactWhatsapp>
            </div>

            <Sticky fixedOn={0}>
                <Header/>
            </Sticky>

            {navbar && <div className="section-after-sticky">{navbar}</div>}
            {!navbar ? (
                <div className="section-after-sticky">{children}</div>
            ) : (
                children
            )}

            <MobileNavigationBar/>
            <Footer/>
        </StyledAppLayout>
    );
}

export default AppLayout;
