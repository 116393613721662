import AppStore from "@component/AppStore";
import Image from "@component/Image";
import Link from "next/link";
import React, {useEffect} from "react";
import styled from "styled-components";
import {getTheme} from "../../utils/utils";
import Box from "../Box";
import Container from "../Container";
import FlexBox from "../FlexBox";
import Grid from "../grid/Grid";
import Icon from "../icon/Icon";
import Typography, {Paragraph} from "../Typography";
import {useDispatch, useSelector} from "react-redux";
import {getAboutUs} from "@store/settingsReducer";

const StyledLink = styled.a`
  position: relative;
  display: block;
  padding: 0.3rem 0rem;
  color: ${getTheme("colors.gray.500")};
  cursor: pointer;
  border-radius: 4px;

  :hover {
    color: ${getTheme("colors.gray.100")};
  }
`;

const Footer: React.FC = () => {
    // @ts-ignore
    const settings = useSelector(state => state.settings.data)
    // @ts-ignore
    const about = useSelector(state => state.settings.about)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAboutUs())
    }, [])

    console.log(settings)

    return (
        <footer>
            <Box bg="#0F3460">
                <Container p="1rem" color="white">
                    <Box py="5rem" overflow="hidden">
                        <Grid container spacing={6}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Link href="/">
                                    <a>
                                        <Image
                                            mb="1.25rem"
                                            src={settings.logo}
                                            alt="logo"
                                        />
                                    </a>
                                </Link>

                                <Paragraph mb="1.25rem" color="gray.500">
                                    {settings?.en_about_us}
                                </Paragraph>

                                <AppStore/>
                            </Grid>

                            <Grid item lg={2} md={6} sm={6} xs={12}>
                                <Typography
                                    fontSize="25px"
                                    fontWeight="600"
                                    mb="1.25rem"
                                    lineHeight="1"
                                >
                                    About Us
                                </Typography>

                                <div>
                                    {about.map((item, ind) => (
                                        <Link href={'/about?active=' + item.id} key={ind}>
                                            <StyledLink>{item.en_title}</StyledLink>
                                        </Link>
                                    ))}
                                </div>
                            </Grid>

                            <Grid item lg={3} md={6} sm={6} xs={12}>
                                <Typography
                                    fontSize="25px"
                                    fontWeight="600"
                                    mb="1.25rem"
                                    lineHeight="1"
                                >
                                    Have a Look
                                </Typography>

                                <div>
                                    <Link href="/products/section/Flash Deals">
                                        <StyledLink>Flash Deals</StyledLink>
                                    </Link>
                                    <Link href="/products/section/Top Ratings">
                                        <StyledLink>Top Ratings</StyledLink>
                                    </Link>
                                    <Link href="/products/section/New Arrivals">
                                        <StyledLink>New Arrivals</StyledLink>
                                    </Link>
                                    <Link href="/products/section/Big Discounts">
                                        <StyledLink>Big Discounts</StyledLink>
                                    </Link>
                                </div>
                            </Grid>

                            <Grid item lg={3} md={6} sm={6} xs={12}>
                                <Typography
                                    fontSize="25px"
                                    fontWeight="600"
                                    mb="1.25rem"
                                    lineHeight="1"
                                >
                                    Contact Us
                                </Typography>
                                <Typography py="0.3rem" color="gray.500">
                                    {settings.address}
                                </Typography>
                                <Typography py="0.3rem">
                                    <Typography as="a" color="gray.500" href={"mailto:+971" + settings.primary_email}>
                                        Email: {settings.primary_email}
                                    </Typography>
                                </Typography>
                                <Typography py="0.3rem" mb="1rem">
                                    <Typography as="a" color="gray.500" href={"phone:+971" + settings.primary_phone}>
                                        Phone: +971 {settings.primary_phone}
                                    </Typography>
                                </Typography>

                                <FlexBox className="flex" mx="-5px">
                                    {iconList.map((item) => (
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer noopenner"
                                            key={item.iconName}
                                        >
                                            <Box
                                                m="5px"
                                                size="small"
                                                p="10px"
                                                bg="rgba(0,0,0,0.2)"
                                                borderRadius="50%"
                                            >
                                                <Icon size="12px" defaultcolor="auto">
                                                    {item.iconName}
                                                </Icon>
                                            </Box>
                                        </a>
                                    ))}
                                </FlexBox>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </footer>
    );
};

const iconList = [
    {iconName: "icons/facebook", url: "https://www.facebook.com/GenxSys"},
    {iconName: "icons/instagram", url: "https://www.instagram.com/genx_system/"},
    {
        iconName: "icons/youtube",
        url: "https://www.youtube.com/watch?v=zh1U92jgl0c",
    },
    {iconName: "icons/linkedin", url: "https://in.linkedin.com/company/genx-systems"},
];

export default Footer;
